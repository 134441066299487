import React from "react";
import "../styles/projects.scss";

// IMAGES
import tuneItLaptop from "../img/projects/tune-it_complete.png";

const Projects = () => {
  return (
    <div id="projects">
      <h3>Projekter</h3>

      <div className="divider"></div>

      <p>Her ser du de projekter, jeg har lavet for andre</p>

      <div className="project">
        <img src={tuneItLaptop} alt="Image of Tune It's website" />

        <div className="project-txt">
          <h4>Tune It</h4>

          <p>
            Jeg har lavet denne hjemmeside for det randrusianske a cappella-kor
            Tune It
          </p>

          <a href="https://www.tune-it.dk/" target="_blank">
            <button>Se siden her</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Projects;
